import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { Chart } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';

export default function ChartC(props) {
  return (
    <Card elevation={3} sx={{ mb: 3, width: '100%', height: 'auto' }}>
      <CardHeader
        title={props.title}
        subheader={props.info}
        sx={{ 
          '& .MuiCardHeader-title': { 
            fontSize: '1.25rem',
            fontWeight: 600 
          },
          '& .MuiCardHeader-subheader': {
            fontSize: '0.9rem'
          }
        }}
      />
      <CardContent sx={{ height: 'auto', minHeight: '300px', position: 'relative' }}>
        <Chart
          type={props.type}
          data={props.state}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: true,
                position: 'bottom'
              }
            }
          }}
        />
      </CardContent>
    </Card>
  );
}
