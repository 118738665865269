import React from 'react';
import Navbar from "./../shared/Navbar";
import { makeStyles } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

const usesStyles = makeStyles((theme) => {
    return {
        root: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            width: '100%',
        },
        page: {
            width: '100%',
            flex: 1,
            boxSizing: "border-box",
            overflow: 'auto',
            height: 'calc(100vh - 64px)' // altura del navbar
        }
    };
});

const Layout = ({ children }) => {
    const classes = usesStyles()
    const location = useLocation();
    const isHomePage = location.pathname === '/';

    return(
        <div className={classes.root}>
            <Navbar />
            <div className={classes.page}>
                {children}
            </div>
        </div>
    );
}

export default Layout;