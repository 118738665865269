import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppBar, Tab, Tabs, Toolbar, Typography, useMediaQuery, useTheme, Container, Box, Button, IconButton, Tooltip } from '@mui/material';
import PolicyIcon from '@mui/icons-material/Policy';
import MenuIcon from '@mui/icons-material/Menu';
import DrawerC from './Drawer';
import CICLOGO from "./../../assets/cic-logo.png";

const Navbar = () => {
    const [value, setValue] = useState('Inicio');
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down('md'));
    const location = useLocation();

    useEffect(() => {
        const path = location.pathname.substring(1);
        setValue(path === '' ? 'Inicio' : path.charAt(0).toUpperCase() + path.slice(1));
    }, [location]);

    const navItems = [
        { label: 'Inicio', path: '/' },
        { label: 'Mapas', path: '/mapas' },
        { label: 'Resultados', path: '/resultados' },
        { label: 'Estadísticas', path: '/estadisticas' },
        { label: 'Video', path: '/video' },
    ];

    return (
        <AppBar position="sticky" elevation={0} sx={{ 
            background: 'linear-gradient(135deg, #731F3E, #8B2649)',
            transition: 'all 0.3s',
            borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
            backdropFilter: 'blur(8px)',
            WebkitBackdropFilter: 'blur(8px)',
        }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters sx={{ minHeight: '70px' }}>
                    <Tooltip title="Sistema Integral de Análisis Geoespacial de Violencia de Género">
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <PolicyIcon sx={{ 
                                display: 'flex', 
                                mr: 1, 
                                color: 'white',
                                fontSize: '2rem',
                                filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.2))'
                            }} />
                            <Typography
                                variant="h6"
                                noWrap
                                component={Link}
                                to="/"
                                sx={{
                                    mr: 2,
                                    display: 'flex',
                                    fontFamily: 'monospace',
                                    fontWeight: 700,
                                    letterSpacing: '.2rem',
                                    color: 'white',
                                    textDecoration: 'none',
                                    flexGrow: { xs: 1, md: 0 },
                                    fontSize: { xs: '1rem', sm: '1.2rem' },
                                    background: 'linear-gradient(to right, #fff, #f0f0f0)',
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                    textShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                    position: 'relative',
                                    '&:after': {
                                        content: '""',
                                        position: 'absolute',
                                        bottom: -4,
                                        left: 0,
                                        width: '100%',
                                        height: '2px',
                                        background: 'linear-gradient(to right, transparent, rgba(255,255,255,0.5), transparent)',
                                        transform: 'scaleX(0)',
                                        transition: 'transform 0.3s ease',
                                    },
                                    '&:hover:after': {
                                        transform: 'scaleX(1)',
                                    }
                                }}
                            >
                                SIAGVG
                            </Typography>
                        </Box>
                    </Tooltip>

                    {isMatch ? (
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ 
                                ml: 2,
                                background: 'rgba(255, 255, 255, 0.1)',
                                '&:hover': {
                                    background: 'rgba(255, 255, 255, 0.2)',
                                }
                            }}
                        >
                            <DrawerC />
                        </IconButton>
                    ) : (
                        <>
                            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
                                <Tabs 
                                    value={value} 
                                    onChange={(e, newValue) => setValue(newValue)}
                                    textColor="inherit"
                                    indicatorColor="secondary"
                                    sx={{ 
                                        '& .MuiTab-root': { 
                                            color: 'rgba(255, 255, 255, 0.8)',
                                            fontSize: '1rem',
                                            fontWeight: 500,
                                            textTransform: 'none',
                                            minWidth: 120,
                                            transition: 'all 0.3s ease',
                                            '&:hover': {
                                                color: 'white',
                                                background: 'rgba(255, 255, 255, 0.1)',
                                            },
                                            '&.Mui-selected': {
                                                color: 'white',
                                                fontWeight: 600,
                                            }
                                        },
                                        '& .MuiTabs-indicator': {
                                            height: 3,
                                            borderRadius: '3px 3px 0 0',
                                            background: 'linear-gradient(to right, transparent, white, transparent)',
                                        }
                                    }}
                                >
                                    {navItems.map((item) => (
                                        <Tab 
                                            key={item.label}
                                            label={item.label} 
                                            value={item.label} 
                                            component={Link} 
                                            to={item.path}
                                        />
                                    ))}
                                </Tabs>
                            </Box>
                           
                        </>
                    )}
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default Navbar;