import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer, GeoJSON, Marker, Popup, useMap } from 'react-leaflet';
import styles from "./../styles/Home.module.css";
import { 
    Alert, 
    CircularProgress, 
    Button, 
    Typography, 
    Box, 
    Divider,
    Card,
    CardContent,
    IconButton,
    Grow,
    Fade,
    Zoom,
    Paper,
    Link,
    TextField,
    InputAdornment,
    Snackbar
} from '@mui/material';
import { 
    DataUsage,
    Psychology,
    Storage,
    Group,
    LocationOn,
    Assessment,
    Security,
    Close,
    KeyboardArrowDown,
    School,
    Memory,
    AccountBalance,
    Phone,
    PhoneInTalk,
    LocalPolice,
    EmergencyShare,
    Search
} from '@mui/icons-material';
import { iconMarker } from '../components/cluster/MarkerIcon';
import * as L from 'leaflet';
import 'leaflet.markercluster';
import axios from "axios";
import cdmxBoundaryData from './../assets/cdmx.json';
import IPNLogo from './../assets/IPN.png';
import CICLogo from './../assets/CIC.png';
import SECTEILogo from './../assets/SECTEI.png';

require("leaflet.markercluster/dist/MarkerCluster.css");
require("leaflet.markercluster/dist/MarkerCluster.Default.css");

// Componente para actualizar la vista del mapa cuando se busca una dirección
const MapUpdater = ({ searchResult }) => {
    const map = useMap();
    
    useEffect(() => {
        if (searchResult && searchResult.lat && searchResult.lon) {
            map.flyTo([searchResult.lat, searchResult.lon], 16);
        }
    }, [map, searchResult]);
    
    return null;
};

const Home = () => {
    const mapRef = useRef(null);
    const mapSectionRef = useRef(null);
    const [zoom] = useState(11);
    const [showAside, setShowAside] = useState(false);
    const [markers, setMarkers] = useState([]);
    const [markerSelected, setMarkerSelected] = useState();
    const [transitionOn, setTransitionOn] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingData, setLoadingData] = useState(true);
    const [showMap, setShowMap] = useState(false);
    const [activeSection, setActiveSection] = useState('info');
    
    // Estados para el buscador de direcciones
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResult, setSearchResult] = useState(null);
    const [searchLoading, setSearchLoading] = useState(false);
    const [searchError, setSearchError] = useState('');
    const [showSearchMarker, setShowSearchMarker] = useState(false);

    const handleScrollToMap = () => {
        mapSectionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    useEffect(() => {
        const loadMapData = async () => {
            try {
                const crimesResponse = await axios.get("https://cic-delitos-4c699404afdc.herokuapp.com/delitos_genero");
                console.log(crimesResponse.data);
                if (crimesResponse.data) {
                    setMarkers(crimesResponse.data);
                }
                setShowMap(true);
            } catch (error) {
                console.error("Error cargando datos:", error);
            } finally {
                setLoadingData(false);
            }
        };

        loadMapData();
    }, []);

    const handleCloseMap = () => {
        setShowMap(false);
    };

    const boundaryStyle = {
        fillColor: "#FFA500",
        fillOpacity: 0.1,
        color: "#FF8C00",
        weight: 2,
    };

    useEffect(() => {
        if (transitionOn) {
            const interval = setInterval(() => {
                try {
                    mapRef.current.invalidateSize();
                } catch {
                    clearInterval(interval);
                }
            }, 10);
        }
    }, [transitionOn]);

    useEffect(() => {
        if (mapRef.current) {
            setTimeout(() => {
                mapRef.current.invalidateSize();
            }, 200);
        }
    }, [mapRef]);

    const points = markers.map((data, id) => {
        if (!isNaN(data.longitud) && !isNaN(data.latitud)) {
            return ({
                type: "Feature",
                properties: { cluster: false, data: data },
                geometry: {
                    type: "Point",
                    coordinates: [
                        parseFloat(data.longitud),
                        parseFloat(data.latitud),
                    ],
                },
            })
        } else return {}
    });

    const renderCluster = (map) => {
        setTimeout(() => {
            const markers = L.markerClusterGroup({
                maxClusterRadius: 80,
                disableClusteringAtZoom: 16,
                spiderfyOnMaxZoom: true,
                showCoverageOnHover: false,
                chunkedLoading: true
            });

            points.forEach((point) => {
                if (point.geometry) {
                    let marker = L.marker([point.geometry.coordinates[1], point.geometry.coordinates[0]], { icon: iconMarker })
                    marker.data = point.properties.data
                    marker.addTo(markers);
                }
            });

            markers.on('click', function (marker) {
                showContentMarkerAside(marker);
            });

            markers.addTo(map.target);

            mapRef.current._layersMaxZoom = 18;
            setLoading(false);
        }, 500);
    }

    const showContentMarkerAside = async (marker) => {
        marker.sourceTarget.latlng = marker.latlng;
        setMarkerSelected(marker.sourceTarget.data);
        mapRef.current.flyTo(marker.latlng, 16)
        setShowAside(true);
        setTransitionOn(true);
    }

    const infoCards = [
        {
            title: "Equipo de Trabajo",
            icon: <Group sx={{ fontSize: 32, color: 'white' }} />,
            content: [
                "Investigadores en ciencia de datos y machine learning",
                "Especialistas en análisis geoespacial y SIG",
                "Desarrolladores de software especializado",
                "Analistas de datos sobre violencia de género",
                "Expertos en seguridad y prevención del delito"
            ]
        },
        {
            title: "Metodología",
            icon: <Psychology sx={{ fontSize: 32, color: 'white' }} />,
            content: [
                "Algoritmos avanzados de machine learning",
                "Análisis predictivo de patrones delictivos",
                "Procesamiento de datos geoespaciales",
                "Modelado estadístico multivariante",
                "Visualización interactiva de datos"
            ]
        },
        {
            title: "Fuentes de Datos",
            icon: <Storage sx={{ fontSize: 32, color: 'white' }} />,
            content: [
                "Bases de datos gubernamentales CDMX",
                "Registros oficiales de incidencias",
                "Datos socioeconómicos y demográficos",
                "Información geoespacial actualizada",
                "Estadísticas de seguridad pública"
            ]
        },
        {
            title: "Funcionalidades",
            icon: <Assessment sx={{ fontSize: 32, color: 'white' }} />,
            content: [
                "Mapeo interactivo de incidencias",
                "Análisis temporal y espacial",
                "Generación de reportes automáticos",
                "Identificación de zonas de riesgo",
                "Seguimiento de tendencias delictivas"
            ]
        }
    ];

    const institutionCards = [
        {
            title: "Instituto Politécnico Nacional",
            acronym: "IPN",
            icon: <School sx={{ fontSize: 32 }} />,
            logo: IPNLogo,
            content: [
                "Institución líder en educación tecnológica en México",
                "Formación de profesionales en áreas estratégicas",
                "Desarrollo de investigación de vanguardia",
                "Compromiso con la innovación tecnológica",
                "Excelencia académica y científica"
            ]
        },
        {
            title: "Centro de Investigación en Computación",
            acronym: "CIC",
            icon: <Memory sx={{ fontSize: 32, color: 'white' }} />,
            logo: CICLogo,
            content: [
                "Laboratorio de Procesamiento Inteligente de Información Geoespacial",
                "Investigación en ciencia de datos",
                "Desarrollo de sistemas inteligentes",
                "Análisis geoespacial avanzado",
                "Innovación en computación aplicada"
            ]
        },
        {
            title: "Secretaría de Educación, Ciencia, Tecnología e Innovación",
            acronym: "SECTEI",
            icon: <AccountBalance sx={{ fontSize: 32, color: 'white' }} />,
            logo: SECTEILogo,
            content: [
                "Impulso a proyectos de innovación en CDMX",
                "Fomento al desarrollo científico",
                "Vinculación academia-gobierno",
                "Políticas públicas basadas en evidencia",
                "Apoyo a la investigación aplicada"
            ]
        }
    ];

    // Mexico City coordinates
    const cdmxCenter = [19.4326, -99.1332]; // Center of Mexico City
    
    // CDMX bounds to ensure the map stays focused on the city
    const cdmxBounds = [
        [19.59, -99.37], // Northwest corner
        [19.05, -98.94]  // Southeast corner
    ];

    // Emergency contact information
    const emergencyContacts = [
        {
            title: "Línea SOS Mujeres *765",
            icon: <PhoneInTalk sx={{ fontSize: 36, color: '#FF4081' }} />,
            description: "Marca *765 desde cualquier teléfono, móvil o fijo, para recibir asistencia de operadoras especializadas en atención de emergencias con perspectiva de género.",
            availability: "Disponible las 24 horas del día, todos los días del año.",
            phone: "*765"
        },
        {
            title: "Línea Nacional de Emergencias 911",
            icon: <EmergencyShare sx={{ fontSize: 36, color: '#FF4081' }} />,
            description: "Disponible para atender emergencias relacionadas con distintos tipos de violencia, incluyendo abuso sexual, violencia de pareja y violencia familiar.",
            availability: "Servicio disponible las 24 horas.",
            phone: "911"
        },
        {
            title: "Línea de Atención a Mujeres en Situación de Violencia",
            icon: <Phone sx={{ fontSize: 36, color: '#FF4081' }} />,
            description: "Brinda atención telefónica a mujeres, sus hijas e hijos en situación de violencia de género, proporcionando intervención en crisis, apoyo psicológico y asesoría jurídica de primer contacto.",
            availability: "Opera las 24 horas del día, los 365 días del año.",
            phone: "800 10 84 053"
        },
        {
            title: "Centros de Justicia para las Mujeres",
            icon: <LocalPolice sx={{ fontSize: 36, color: '#FF4081' }} />,
            description: "Ofrecen servicios especializados e integrales a mujeres y niñas víctimas de violencia familiar y de género, garantizando su derecho a una vida libre de violencia.",
            availability: "Consulta horarios y ubicaciones en tu localidad.",
            phone: ""
        }
    ];

    // Función para buscar direcciones usando lugares predefinidos y geocodificación básica
    const handleAddressSearch = async (e) => {
        e.preventDefault();
        
        if (!searchQuery.trim()) return;
        
        setSearchLoading(true);
        setSearchError('');
        
        try {
            // Diccionario de lugares comunes en CDMX con sus coordenadas
            const commonPlaces = {
                // Zonas y colonias principales
                'zocalo': { lat: 19.4326, lon: -99.1332, name: 'Zócalo, Ciudad de México' },
                'centro': { lat: 19.4326, lon: -99.1332, name: 'Centro Histórico, Ciudad de México' },
                'chapultepec': { lat: 19.4200, lon: -99.1870, name: 'Bosque de Chapultepec, Ciudad de México' },
                'reforma': { lat: 19.4260, lon: -99.1674, name: 'Paseo de la Reforma, Ciudad de México' },
                'coyoacan': { lat: 19.3500, lon: -99.1612, name: 'Coyoacán, Ciudad de México' },
                'polanco': { lat: 19.4320, lon: -99.1920, name: 'Polanco, Ciudad de México' },
                'condesa': { lat: 19.4138, lon: -99.1780, name: 'La Condesa, Ciudad de México' },
                'roma': { lat: 19.4169, lon: -99.1662, name: 'Roma, Ciudad de México' },
                'santa fe': { lat: 19.3656, lon: -99.2739, name: 'Santa Fe, Ciudad de México' },
                'tlatelolco': { lat: 19.4520, lon: -99.1370, name: 'Tlatelolco, Ciudad de México' },
                'xochimilco': { lat: 19.2569, lon: -99.1042, name: 'Xochimilco, Ciudad de México' },
                'iztapalapa': { lat: 19.3579, lon: -99.0572, name: 'Iztapalapa, Ciudad de México' },
                'tepito': { lat: 19.4425, lon: -99.1269, name: 'Tepito, Ciudad de México' },
                'doctores': { lat: 19.4178, lon: -99.1448, name: 'Doctores, Ciudad de México' },
                'juarez': { lat: 19.4265, lon: -99.1587, name: 'Juárez, Ciudad de México' },
                'napoles': { lat: 19.3935, lon: -99.1755, name: 'Nápoles, Ciudad de México' },
                'del valle': { lat: 19.3868, lon: -99.1668, name: 'Del Valle, Ciudad de México' },
                'narvarte': { lat: 19.3953, lon: -99.1550, name: 'Narvarte, Ciudad de México' },
                'san angel': { lat: 19.3467, lon: -99.1869, name: 'San Ángel, Ciudad de México' },
                'pedregal': { lat: 19.3130, lon: -99.2040, name: 'Pedregal, Ciudad de México' },
                'tlalpan': { lat: 19.2931, lon: -99.1664, name: 'Tlalpan, Ciudad de México' },
                'lindavista': { lat: 19.4908, lon: -99.1325, name: 'Lindavista, Ciudad de México' },
                'azcapotzalco': { lat: 19.4836, lon: -99.1843, name: 'Azcapotzalco, Ciudad de México' },
                'mixcoac': { lat: 19.3764, lon: -99.1869, name: 'Mixcoac, Ciudad de México' },
                
                // Lugares específicos
                'palacio nacional': { lat: 19.4326, lon: -99.1319, name: 'Palacio Nacional, Ciudad de México' },
                'bellas artes': { lat: 19.4352, lon: -99.1413, name: 'Palacio de Bellas Artes, Ciudad de México' },
                'catedral': { lat: 19.4339, lon: -99.1334, name: 'Catedral Metropolitana, Ciudad de México' },
                'alameda': { lat: 19.4352, lon: -99.1449, name: 'Alameda Central, Ciudad de México' },
                'angel': { lat: 19.4270, lon: -99.1675, name: 'Ángel de la Independencia, Ciudad de México' },
                'monumento revolucion': { lat: 19.4363, lon: -99.1546, name: 'Monumento a la Revolución, Ciudad de México' },
                'castillo': { lat: 19.4204, lon: -99.1821, name: 'Castillo de Chapultepec, Ciudad de México' },
                'antropologia': { lat: 19.4260, lon: -99.1865, name: 'Museo de Antropología, Ciudad de México' },
                'unam': { lat: 19.3324, lon: -99.1868, name: 'Ciudad Universitaria (UNAM), Ciudad de México' },
                'estadio azteca': { lat: 19.3029, lon: -99.1505, name: 'Estadio Azteca, Ciudad de México' },
                'estadio olimpico': { lat: 19.3313, lon: -99.1908, name: 'Estadio Olímpico Universitario, Ciudad de México' },
                'basilica': { lat: 19.4849, lon: -99.1173, name: 'Basílica de Guadalupe, Ciudad de México' },
                'frida kahlo': { lat: 19.3550, lon: -99.1626, name: 'Museo Frida Kahlo, Ciudad de México' },
                'soumaya': { lat: 19.4405, lon: -99.2047, name: 'Museo Soumaya, Ciudad de México' },
                'tamayo': { lat: 19.4258, lon: -99.1763, name: 'Museo Tamayo, Ciudad de México' },
                'auditorio nacional': { lat: 19.4255, lon: -99.1921, name: 'Auditorio Nacional, Ciudad de México' },
                'arena mexico': { lat: 19.4235, lon: -99.1456, name: 'Arena México, Ciudad de México' },
                'arena cdmx': { lat: 19.4033, lon: -99.0911, name: 'Arena Ciudad de México, Ciudad de México' },
                'foro sol': { lat: 19.4061, lon: -99.0990, name: 'Foro Sol, Ciudad de México' },
                'autodromo': { lat: 19.4047, lon: -99.0909, name: 'Autódromo Hermanos Rodríguez, Ciudad de México' },
                'cineteca': { lat: 19.3598, lon: -99.1613, name: 'Cineteca Nacional, Ciudad de México' },
                'cibeles': { lat: 19.4177, lon: -99.1677, name: 'Fuente de Cibeles, Ciudad de México' },
                'torre latino': { lat: 19.4339, lon: -99.1406, name: 'Torre Latinoamericana, Ciudad de México' },
                'torre mayor': { lat: 19.4242, lon: -99.1768, name: 'Torre Mayor, Ciudad de México' },
                'wtc': { lat: 19.3957, lon: -99.1756, name: 'World Trade Center, Ciudad de México' },
                'chapultepec 2': { lat: 19.4131, lon: -99.1932, name: 'Segunda Sección de Chapultepec, Ciudad de México' },
                'chapultepec 3': { lat: 19.4018, lon: -99.2068, name: 'Tercera Sección de Chapultepec, Ciudad de México' },
                'parque mexico': { lat: 19.4119, lon: -99.1710, name: 'Parque México, Ciudad de México' },
                'parque españa': { lat: 19.4185, lon: -99.1700, name: 'Parque España, Ciudad de México' },
                'viveros': { lat: 19.3518, lon: -99.1761, name: 'Viveros de Coyoacán, Ciudad de México' }
            };
            
            // Buscar coincidencias en lugares comunes
            const searchLower = searchQuery.toLowerCase();
            
            // Buscar coincidencia exacta primero
            let matchedPlace = Object.keys(commonPlaces).find(key => searchLower === key);
            
            // Si no hay coincidencia exacta, buscar coincidencia parcial
            if (!matchedPlace) {
                matchedPlace = Object.keys(commonPlaces).find(key => searchLower.includes(key));
            }
            
            if (matchedPlace) {
                const place = commonPlaces[matchedPlace];
                setSearchResult({
                    lat: place.lat,
                    lon: place.lon,
                    display_name: place.name
                });
                setShowSearchMarker(true);
            } else {
                // Si no se encuentra en lugares predefinidos, intentar con OpenStreetMap Nominatim
                // (esto puede fallar por CORS, pero lo intentamos como último recurso)
                try {
                    const response = await fetch(
                        `https://nominatim.openstreetmap.org/search?` +
                        `format=json&` +
                        `q=${encodeURIComponent(searchQuery + ', Ciudad de México, México')}&` +
                        `limit=1`
                    );
                    
                    if (response.ok) {
                        const data = await response.json();
                        
                        if (data && data.length > 0) {
                            setSearchResult(data[0]);
                            setShowSearchMarker(true);
                        } else {
                            setSearchError('No se encontró la dirección. Intente con un lugar conocido como "Zócalo" o "Reforma".');
                            setShowSearchMarker(false);
                        }
                    } else {
                        throw new Error('Error en la búsqueda');
                    }
                } catch (nominatimError) {
                    console.error('Error con Nominatim:', nominatimError);
                    setSearchError('No se encontró la dirección. Intente con un lugar conocido como "Zócalo" o "Reforma".');
                    setShowSearchMarker(false);
                }
            }
        } catch (error) {
            console.error('Error al buscar dirección:', error);
            setSearchError('Error al buscar la dirección. Intente con un lugar conocido como "Zócalo" o "Reforma".');
            setShowSearchMarker(false);
        } finally {
            setSearchLoading(false);
        }
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.mainContent}>
                <section className={styles.titleSection}>
                    <Fade in timeout={1000}>
                        <div>
                            <Typography variant="h1" className={styles.titleMain}>
                                Sistema Integral de Análisis Geoespacial de Violencia de Género
                            </Typography>
                            <Typography variant="subtitle1" className={styles.subtitle}>
                                Herramienta avanzada para la identificación, análisis y seguimiento de áreas con alta incidencia 
                                delictiva de género en la Ciudad de México, desarrollada por instituciones líderes en investigación.
                            </Typography>
                        </div>
                    </Fade>
                    <div className={styles.scrollIndicator} />
                </section>

                <div className={styles.projectInfo}>
                    <Typography variant="h4" className={styles.sectionTitle}>
                        Instituciones Participantes
                    </Typography>
                    <Typography variant="subtitle1" className={styles.sectionSubtitle}>
                        Colaboración interinstitucional para el desarrollo de soluciones tecnológicas innovadoras
                    </Typography>
                    
                    <div className={styles.infoGrid}>
                        {institutionCards.map((card, index) => (
                            <Grow 
                                in 
                                timeout={1000 + (index * 200)}
                                key={index}
                            >
                                <Card className={styles.infoCard}>
                                    <div className={styles.infoCardHeader}>
                                        <div className={styles.logoSpace}>
                                            <img 
                                                src={card.logo} 
                                                alt={`Logo ${card.acronym}`}
                                                className={styles.institutionLogo}
                                            />
                                        </div>
                                        <div>
                                            <Typography variant="h6" sx={{ color: 'white' }}>
                                                {card.acronym}
                                            </Typography>
                                            <Typography variant="subtitle2" sx={{ color: 'rgba(255,255,255,0.9)', mt: 0.5 }}>
                                                {card.title}
                                            </Typography>
                                        </div>
                                    </div>
                                    <CardContent className={styles.infoCardContent}>
                                        {card.content.map((item, idx) => (
                                            <Typography key={idx} className={styles.cardItem}>
                                                {item}
                                            </Typography>
                                        ))}
                                    </CardContent>
                                </Card>
                            </Grow>
                        ))}
                    </div>

                    <Divider sx={{ my: 6, bgcolor: 'rgba(255,255,255,0.1)' }} />

                    <Typography variant="h4" className={styles.sectionTitle}>
                        Características del Proyecto
                    </Typography>
                    <Typography variant="subtitle1" className={styles.sectionSubtitle}>
                        Elementos clave que definen nuestro sistema de análisis
                    </Typography>

                    <div className={styles.infoGrid}>
                        {infoCards.map((card, index) => (
                            <Grow 
                                in 
                                timeout={1000 + (index * 200)}
                                key={index}
                            >
                                <Card className={styles.infoCard}>
                                    <div className={styles.infoCardHeader}>
                                        {card.icon}
                                        <Typography variant="h6" sx={{ color: 'white' }}>
                                            {card.title}
                                        </Typography>
                                    </div>
                                    <CardContent className={styles.infoCardContent}>
                                        {card.content.map((item, idx) => (
                                            <Typography key={idx} className={styles.cardItem}>
                                                {item}
                                            </Typography>
                                        ))}
                                    </CardContent>
                                </Card>
                            </Grow>
                        ))}
                    </div>

                    <Fade in timeout={2000}>
                        <div className={styles.mapButtonContainer}>
                            <Typography variant="h6" gutterBottom sx={{ color: 'white', textAlign: 'center', mb: 3 }}>
                                Explore nuestro mapa interactivo para visualizar y analizar patrones de violencia de género
                            </Typography>
                            <Button 
                                variant="contained"
                                className={styles.loadMapButton}
                                onClick={handleScrollToMap}
                                startIcon={<LocationOn />}
                            >
                                IR AL MAPA INTERACTIVO
                            </Button>
                        </div>
                    </Fade>
                </div>

                <div className={styles.mapSection} ref={mapSectionRef}>
                    <Typography variant="h4" className={styles.mapTitle}>
                        Visualización Geoespacial de Incidencias
                    </Typography>
                    <Typography variant="subtitle1" className={styles.mapSubtitle}>
                        Explore el mapa interactivo para analizar la distribución espacial de los casos reportados
                    </Typography>
                    
                    {/* Buscador de direcciones */}
                    <Paper component="form" className={styles.searchContainer} onSubmit={handleAddressSearch}>
                        <TextField
                            fullWidth
                            placeholder="Buscar dirección en CDMX (ej. Reforma 222, Zócalo, etc.)"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            variant="outlined"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton 
                                            type="submit"
                                            disabled={searchLoading}
                                            color="primary"
                                            aria-label="buscar dirección"
                                        >
                                            {searchLoading ? <CircularProgress size={24} /> : <Search />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                className: styles.searchInput
                            }}
                        />
                    </Paper>
                    
                    <div className={styles.fullWidthMap}>
                        <div className={styles.mapContainer}>
                            <div className={styles.mapWrapper}>
                                {loadingData ? (
                                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%" gap={2}>
                                        <CircularProgress size={60} />
                                        <Typography variant="h6" color="textSecondary">
                                            Cargando datos del mapa...
                                        </Typography>
                                    </Box>
                                ) : (
                                    <MapContainer
                                        preferCanvas={true}
                                        id="mymap"
                                        center={cdmxCenter}
                                        zoom={zoom}
                                        maxBounds={cdmxBounds}
                                        maxBoundsViscosity={1.0}
                                        ref={mapRef}
                                        style={{ height: '100%', width: "100%" }}
                                        whenReady={(map) => {
                                            renderCluster(map);
                                        }}
                                    >
                                        <TileLayer
                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                            minZoom={10}
                                            maxZoom={18}
                                        />
                                        <GeoJSON 
                                            data={cdmxBoundaryData} 
                                            style={boundaryStyle}
                                        />
                                        
                                        {/* Marcador para la dirección buscada */}
                                        {showSearchMarker && searchResult && (
                                            <Marker 
                                                position={[searchResult.lat, searchResult.lon]}
                                                icon={L.divIcon({
                                                    className: styles.searchMarker,
                                                    html: '<div><span></span></div>',
                                                    iconSize: [30, 30],
                                                    iconAnchor: [15, 15]
                                                })}
                                            >
                                                <Popup>
                                                    <Typography variant="subtitle1" fontWeight="bold">
                                                        {searchResult.display_name}
                                                    </Typography>
                                                </Popup>
                                            </Marker>
                                        )}
                                        
                                        {/* Componente para actualizar la vista del mapa */}
                                        <MapUpdater searchResult={searchResult} />
                                    </MapContainer>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.emergencySection}>
                    <Typography variant="h4" className={styles.emergencyTitle}>
                        Líneas de Ayuda y Emergencia
                    </Typography>
                    <Typography variant="subtitle1" className={styles.emergencySubtitle}>
                        Si necesitas ayuda o deseas denunciar situaciones de violencia de género en la Ciudad de México, 
                        puedes comunicarte a las siguientes líneas de atención:
                    </Typography>

                    <div className={styles.emergencyGrid}>
                        {emergencyContacts.map((contact, index) => (
                            <Grow 
                                in 
                                timeout={800 + (index * 200)}
                                key={index}
                            >
                                <Card className={styles.emergencyCard}>
                                    <div className={styles.emergencyCardHeader}>
                                        <div className={styles.emergencyIconContainer}>
                                            {contact.icon}
                                        </div>
                                        <Typography variant="h6" className={styles.emergencyCardTitle}>
                                            {contact.title}
                                        </Typography>
                                    </div>
                                    <CardContent className={styles.emergencyCardContent}>
                                        <Typography variant="body1" className={styles.emergencyDescription}>
                                            {contact.description}
                                        </Typography>
                                        <Typography variant="body2" className={styles.emergencyAvailability}>
                                            {contact.availability}
                                        </Typography>
                                        {contact.phone && (
                                            <Typography variant="h5" className={styles.emergencyPhone}>
                                                {contact.phone}
                                            </Typography>
                                        )}
                                    </CardContent>
                                </Card>
                            </Grow>
                        ))}
                    </div>

                    <Paper elevation={3} className={styles.emergencyMessage}>
                        <Typography variant="body1" align="center">
                            Recuerda que no estás sola y que existen instituciones dispuestas a brindarte apoyo y protección.
                        </Typography>
                    </Paper>
                </div>

                {markerSelected && (
                    <Zoom in timeout={300}>
                        <div className={`${styles.aside} ${showAside ? styles.animated : ''}`}>
                            <div className={styles.container}>
                                <Alert 
                                    severity="error" 
                                    icon={<Security />}
                                    className={styles.alertCard}
                                >
                                    <Typography variant="h6" gutterBottom>
                                        Delito: <strong>{markerSelected.Delito}</strong>
                                    </Typography>
                                    <Divider sx={{ my: 1 }} />
                                    <Typography variant="body1" gutterBottom>
                                        Edad: <strong>{markerSelected.Edad}</strong>
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        Hora del delito: <strong>{markerSelected.HoraHecho}</strong>
                                    </Typography>
                                    <Typography variant="body1">
                                        Fecha del delito: <strong>{markerSelected.FechaHecho}</strong>
                                    </Typography>
                                </Alert>
                            </div>
                        </div>
                    </Zoom>
                )}

                {/* Snackbar para mostrar errores de búsqueda */}
                <Snackbar
                    open={!!searchError}
                    autoHideDuration={6000}
                    onClose={() => setSearchError('')}
                    message={searchError}
                    action={
                        <IconButton size="small" color="inherit" onClick={() => setSearchError('')}>
                            <Close fontSize="small" />
                        </IconButton>
                    }
                />
            </div>
        </div>
    );
}

export default Home;