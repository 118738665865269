import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const Video = () => {
    return (
        <Box sx={{
            minHeight: '100vh',
            background: 'linear-gradient(135deg, #731F3E, #8B2649)',
            py: 4
        }}>
            <Container maxWidth="lg">
                <Typography 
                    variant="h3" 
                    component="h1" 
                    sx={{ 
                        color: 'white',
                        mb: 4,
                        textAlign: 'center',
                        fontWeight: 700,
                        background: 'linear-gradient(to right, #fff, #f0f0f0)',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        textShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    }}
                >
                    Video Informativo
                </Typography>
                <Box sx={{
                    position: 'relative',
                    width: '100%',
                    paddingTop: '56.25%', // 16:9 Aspect Ratio
                    borderRadius: '16px',
                    overflow: 'hidden',
                    boxShadow: '0 8px 32px rgba(0, 0, 0, 0.15)',
                    border: '2px solid rgba(255, 255, 255, 0.2)',
                    background: 'rgba(255, 255, 255, 0.05)',
                    backdropFilter: 'blur(8px)',
                    WebkitBackdropFilter: 'blur(8px)',
                }}>
                    <iframe
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            width: '100%',
                            height: '100%',
                            border: 'none',
                        }}
                        src="https://www.youtube.com/embed/apllSSuckdA"
                        title="Video Informativo"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                </Box>
            </Container>
        </Box>
    );
};

export default Video; 