import React, { useState } from 'react';
import { 
  Typography, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Box,
  Tabs,
  Tab,
  Divider,
  Card,
  CardContent,
  Chip,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useMediaQuery,
  useTheme,
  Fade,
  Container
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AssessmentIcon from '@mui/icons-material/Assessment';
import InsightsIcon from '@mui/icons-material/Insights';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import AsociationMap from '../components/cluster/asociationMap/AsociationMap';
import styles from '../styles/Results.module.css';

// Datos completos del archivo JSON
const resultsData = {
  "document": [
    {
      "type": "table",
      "headers": ["Regla", "Incidencia A", "Incidencia A U B", "Sop. (A U B)", "Conf."],
      "subheaders": [null, null, null, ["Weka", "Python"], ["Weka", "Python"]],
      "rows": [
        ["La Magdalena Contreras => Violencia familiar", "2554", "1659", "0.02", "0.0207", "0.65", "0.6495"],
        ["Milpa alta => Violencia familiar", "1235", "801", "0.01", "0.0100", "0.65", "0.6485"],
        ["Xochimilco => Violencia familiar", "3742", "2329", "0.03", "0.0291", "0.62", "0.6223"]
      ]
    },
    {
      "type": "list",
      "items": [
        "Reportado en las carpetas de investigación, el 64 % de las personas que han sido víctimas de un delito en la delegación La Magdalena contreras han sufrido violencia familiar.",
        "Reportado en las carpetas de investigación, el 64 % de las personas que han sido víctimas de un delito en la delegación Milpa Alta han sufrido violencia familiar.",
        "Con base en los registros oficiales, el 64 % de los casos de violencia familiar en la alcaldía Xochimilco tienen como víctima a una mujer."
      ]
    },
    {
      "type": "table",
      "headers": ["Regla", "Incidencia A", "Incidencia A U B", "Sop. (A U B)", "Conf."],
      "subheaders": [null, null, null, ["Weka", "Python"], ["Weka", "Python"]],
      "rows": [
        ["Lesiones intencionales => Cuajimalpa de Morelos", "1", "1", "0.00001", "1.2x10^-5", "1", "1"],
        ["Intimidación (evitar denuncia, aporte información o pruebas) => Venustiano Carranza", "1", "1", "0.00001", "1.2x10^-5", "1", "1"],
        ["Violación tumultuaria equiparada => Iztapalapa", "1", "1", "0.00001", "1.2x10^-5", "1", "1"]
      ]
    },
    {
      "type": "list",
      "items": [
        "Según las denuncias presentadas ante la fiscalía, en la alcaldía Cuajimalpa de Morelos se han reportado casos de lesiones intencionales en contra de mujeres.",
        "Reportado en las carpetas de investigación, en la alcaldía Venustiano Carranza se ha registrado el delito de intimidación en contra de mujeres.",
        "Con base en los registros oficiales, en la alcaldía Iztapalapa se ha denunciado el delito de violación tumultaria en contra de mujeres."
      ]
    },
    {
      "type": "table",
      "headers": ["Regla", "Incidencia A", "Incidencia A U B", "Sop. (A U B)", "Conf."],
      "subheaders": [null, null, null, ["Weka", "Python"], ["Weka", "Python"]],
      "rows": [
        ["Abuso de confianza => Adulto", "2013", "1697", "0.02", "0.0212", "0.84", "0.8403"],
        ["Amenazas => Adulto", "16739", "12944", "0.20", "0.1617", "0.77", "0.7734"],
        ["Lesiones por golpes => Adulto", "1925", "1392", "0.02", "0.0173", "0.72", "0.7231"],
        ["Adulto mayor => Violencia familiar", "5570", "3724", "0.05", "0.0465", "0.67", "0.6685"],
        ["Violencia familiar => Adulto", "43998", "29051", "0.36", "0.3630", "0.66", "0.6602"]
      ]
    },
    {
      "type": "list",
      "items": [
        "Según las denuncias registradas en la fiscalía, el 84 % de las víctimas de abuso de confianza tienen entre 30 y 59 años.",
        "Reportado en las carpetas de investigación, el 77 % de las víctimas de amenazas corresponden a personas de entre 30 y 59 años.",
        "Con base en los registros oficiales, el 72 % de las personas que sufren lesiones por golpes tienen entre 30 y 59 años.",
        "Según las denuncias presentadas ante la fiscalía, el 66 % de las personas mayores de 60 años han sido víctimas de violencia familiar.",
        "Reportado en las carpetas de investigación, el 66 % de las víctimas de violencia familiar corresponden a personas de entre 30 y 59 años."
      ]
    },
    {
      "type": "table",
      "headers": ["Regla", "Incidencia A", "Incidencia A U B", "Sop. (A U B)", "Conf."],
      "subheaders": [null, null, null, ["Weka", "Python"], ["Weka", "Python"]],
      "rows": [
        ["Lesiones por arma blanca => Adulto", "163", "125", "0.002", "0.0015", "0.77", "0.7668"],
        ["Pornografía => Joven", "143", "101", "0.001", "0.0012", "0.67", "0.6724"],
        ["Corrupción de personas => Joven", "345", "232", "0.003", "0.0028", "0.67", "0.6685"],
        ["Lesiones por arma de fuego =>Adulto", "258", "163", "0.002", "0.0020", "0.63", "0.6317"]
      ]
    },
    {
      "type": "list",
      "items": [
        "Según las denuncias presentadas, el 77 % de las personas de entre 30 y 59 años han sido víctimas de lesiones por arma blanca.",
        "Con base en los reportes de la fiscalía, el 66 % de las víctimas de corrupción de personas tienen entre 12 y 29 años.",
        "Reportado en las carpetas de investigación, el 66 % de las víctimas de lesiones por arma de fuego corresponden a personas de entre 30 y 59 años."
      ]
    },
    {
      "type": "table",
      "headers": ["Regla", "Incidencia A", "Incidencia A U B", "Sop. (A U B)", "Conf."],
      "subheaders": [null, null, null, ["Weka", "Python"], ["Weka", "Python"]],
      "rows": [
        ["Tortura =>Adulto", "22", "21", "0.0002", "0.00026", "0.95", "0.9454"],
        ["Intimidación => Adulto", "14", "12", "0.0001", "0.00014", "0.86", "0.8571"],
        ["Feminicidio por arma blanca =>Adulto", "31", "23", "0.0003", "0.00028", "0.74", "0.7419"],
        ["Tentativa de homicidio => Adulto", "55", "39", "0.0005", "0.00048", "0.71", "0.7090"],
        ["Violación tumultuaria => Joven", "47", "32", "0.0004", "0.00031", "0.68", "0.6808"],
        ["Acoso sexual agravado => Joven", "86", "56", "0.0007", "0.00069", "0.65", "0.6511"],
        ["Feminicidio por disparo de arma de fuego => Adulto", "28", "18", "0.0002", "0.00022", "0.64", "0.6428"],
        ["Feminicidio por golpes => Adulto", "18", "11", "0.0001", "0.00013", "0.61", "0.6111"]
      ]
    },
    {
      "type": "list",
      "items": [
        "Conforme a las denuncias registradas, el 94 % de las víctimas de tortura tienen entre 30 y 59 años.",
        "Según los reportes oficiales, el 85 % de las víctimas de lesiones por intimidación tienen entre 30 y 59 años.",
        "Reportado por las carpetas de investigación, el 74 % de las víctimas de feminicidio por arma blanca corresponden a personas de entre 30 y 59 años.",
        "Según las denuncias presentadas, el 70 % de las personas de entre 30 y 59 años han sido víctimas de tentativa de homicidio.",
        "Con base en los reportes de la fiscalía, el 68 % de las víctimas de violación tumultuaria tienen entre 12 y 29 años.",
        "Reportes de denuncias interpuestas muestran que el 65 % de las víctimas de acoso sexual agravado tienen entre 12 y 29 años.",
        "Según las carpetas de investigación, el 64 % de las víctimas de feminicidio por arma de fuego tienen entre 30 y 59 años.",
        "Reportado en las denuncias ante la fiscalía, el 61 % de las víctimas de feminicidio por arma de fuego tienen entre 30 y 59 años.",
        "Conforme a los reportes de la fiscalía, el 61 % de las víctimas de feminicidio por golpes corresponden a personas de entre 30 y 59 años."
      ]
    },
    {
      "type": "table",
      "headers": ["Regla", "Incidencia A", "Incidencia A U B", "Sop.", "Python", "Weka", "Python"],
      "subheaders": [],
      "rows": [
        ["Corrupción de personas menores, Coyoacán => Joven", "15", "14", "0.0002", "0.00017", "0.93", "0.9333"],
        ["Violación, Milpa Alta => Joven", "28", "24", "0.0003", "0.00029", "0.86", "0.8571"],
        ["Corrupción de personas menores de, Álvaro Obregón => Joven", "15", "11", "0.0001", "0.00013", "0.73", "0.7333"],
        ["Pornografía, Iztapalapa => Joven", "36", "27", "0.0003", "0.00033", "0.75", "0.7500"]
      ]
    },
    {
      "type": "list",
      "items": [
        "Reportado por las denuncias realizadas ante la fiscalía y las carpetas de investigación, las víctimas de corrupción de menores son personas entre los 12 a 29 años y tienen un 93 % de probabilidad de estar relacionadas con la alcaldía Coyoacan.",
        "Según las denuncias registradas en la fiscalía, el delito de violación en Xochimilco ocurre en un 85 % de los casos a personas entre los 12 a 29 años de edad.",
        "Con base en las carpetas de investigación, el 67 % de los casos de corrupción de personas en Alvaro obregon están asociados con personas entre los 12 a 29 años de edad.",
        "De acuerdo con los registros oficiales, la pornigrafía ocuure en contra de jovenes entre los 12 a 29 años."
      ]
    },
    {
      "type": "table",
      "headers": ["Regla", "Incidencia A U B", "Incidencia (AU B) U C", "Sop. (A U B) U C", "Conf."],
      "subheaders": [null, null, null, ["Weka", "Python"], ["Weka", "Python"]],
      "rows": [
        ["Homicidio por golpes, Noche=> Cuauhtémoc", "4", "4", "0.00005", "4.9x10^-5", "1", "1"],
        ["Privación de la libertad personal (realizar acto sexual), Xochimilco => Tarde", "3", "3", "0.00004", "3.7x10^-5", "1", "1"],
        ["Corrupción de personas, Milpa Alta => Tarde", "6", "4", "0.00005", "4.9x10^-5", "0.67", "0.6666"],
        ["Acoso sexual agravado en contra de menores Azcapotzalco => Noche", "7", "5", "0.00006", "6.2x10^-5", "0.71", "0.7142"],
        ["Acoso sexual agravado en contra de menores Iztacalco => Tarde", "9", "6", "0.00007", "7.4x10^-5", "0.67", "0.6666"],
        ["Feminicidio por arma blanca, Gustavo A Madero => Mañana", "6", "4", "0.00005", "4.9x10^-5", "0.67", "0.6666"],
        ["Plagio o secuestro, madrugada => Iztapalapa", "3", "2", "0.00002", "2.4x10^-5", "0.67", "0.6666"],
        ["Desaparición forzada de personas, Cuauhtémoc ==> Mañana", "3", "2", "0.00002", "2.4x10^-5", "0.67", "0.6666"],
        ["Privación de la libertad personal (realizar acto sexual), noche => Iztacalco", "3", "2", "0.00002", "2.4x10^-5", "0.67", "0.6666"],
        ["Feminicidio por golpes Xochimilco =>Mañana", "3", "2", "0.00002", "2.4x10^-5", "0.67", "0.6666"]
      ]
    },
    {
      "type": "list",
      "items": [
        "Según los reportes de denuncias, el delito de corrupción de personas en la alcaldía Milpa Alta ocurre con mayor frecuencia en el turno de la tarde, con una probabilidad una tasa de incidencia del 67 %.",
        "Conforme a las denuncias registradas, el delito de acoso sexual agravado en contra de menores en la alcaldía Azcapotzalco se presenta con mayor frecuencia en el turno de la noche, con una tasa de incidencia del 71 % (0.7142).",
        "Según los datos de denuncias, el 67 % de los casos de acoso sexual agravado contra menores en Iztacalco tienen lugar en la tarde.",
        "Reportado en las carpetas de investigación, el 67 % de los feminicidios por arma blanca en Gustavo A. Madero ocurren en la mañana.",
        "Según las denuncias registradas, el 67 % de los casos de plagio o secuestro en la madrugada están relacionados con la alcaldía Iztapalapa.",
        "Con base en las investigaciones realizadas, el 67 % de los casos de desaparición forzada de personas en Cuauhtémoc ocurren en la mañana.",
        "De acuerdo con las denuncias presentadas ante la fiscalía, el 67 % de los casos de privación de la libertad personal con fines de agresión sexual en la noche ocurren en Iztacalco.",
        "Reportado en las carpetas de investigación, el 67 % de los feminicidios por golpes en Xochimilco ocurren en la mañana."
      ]
    }
  ]
};

// Datos adicionales para la tabla de delitos violentos
const delitosViolentosData = {
  "headers": ["Regla", "Incidencia A", "Incidencia A U B", "Conf."],
  "subheaders": [null, null, null, ["Weka", "Python"]],
  "rows": [
    ["Robo a transeúnte en vía pública con violencia => Adulto", "7351", "5229", ".71", "0.7113"],
    ["Robo a casa habitación sin violencia, Cuauhtémoc => adulto", "485", "374", ".75", "0.7711"],
    ["Robo a negocio con violencia => Adulto", "2063", "1573", ".76", "0.7624"],
    ["Robo a transeúnte saliendo del cajero con violencia, Miguel Hidalgo => Adulto", "10", "10", "1", "1"],
    ["Robo de motocicleta con violencia, Iztacalco => Noche", "7", "7", "1", "1"],
    ["Secuestro express (para cometer robo o extorsión), Tlalpan => Tarde", "3", "3", "1", "1"],
    ["Contra el cumplimiento de la obligación alimentaria, Cuajimalpa de Morelos => Mañana", "5", "5", "1", "1"]
  ]
};

// Componente para renderizar tablas
const DataTable = ({ headers, subheaders, rows }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <TableContainer component={Paper} elevation={3} sx={{ 
      mb: 4, 
      borderRadius: '12px',
      overflow: 'hidden',
      boxShadow: '0 6px 16px rgba(0, 0, 0, 0.08)'
    }}>
      <Table size={isMobile ? "small" : "medium"}>
        <TableHead>
          <TableRow sx={{ backgroundColor: '#800040' }}>
            {headers.map((header, index) => (
              <TableCell 
                key={index} 
                align={index === 0 ? "left" : "center"}
                colSpan={index === 3 || index === 4 ? 2 : 1}
                sx={{ 
                  color: 'white', 
                  fontWeight: 'bold',
                  fontSize: isMobile ? '0.75rem' : '0.875rem'
                }}
              >
                {header}
              </TableCell>
            ))}
          </TableRow>
          {subheaders && subheaders.some(sh => sh !== null) && (
            <TableRow sx={{ backgroundColor: '#f8e5ee' }}>
              {subheaders.map((subheader, index) => {
                if (Array.isArray(subheader)) {
                  return subheader.map((sh, i) => (
                    <TableCell 
                      key={`${index}-${i}`} 
                      align="center"
                      sx={{ 
                        fontWeight: 'bold',
                        color: '#800040',
                        fontSize: isMobile ? '0.7rem' : '0.8rem'
                      }}
                    >
                      {sh}
                    </TableCell>
                  ));
                }
                return (
                  <TableCell 
                    key={index} 
                    sx={{ 
                      color: '#800040',
                      fontSize: isMobile ? '0.7rem' : '0.8rem'
                    }}
                  />
                );
              })}
            </TableRow>
          )}
        </TableHead>
        <TableBody>
          {rows.map((row, rowIndex) => (
            <TableRow 
              key={rowIndex}
              sx={{ 
                '&:nth-of-type(odd)': { backgroundColor: '#f9f9f9' },
                '&:hover': { backgroundColor: '#f0f0f0' },
                transition: 'background-color 0.2s'
              }}
            >
              {row.map((cell, cellIndex) => (
                <TableCell 
                  key={cellIndex} 
                  align={cellIndex === 0 ? "left" : "center"}
                  sx={{ 
                    fontSize: isMobile ? '0.7rem' : '0.8rem',
                    fontWeight: cellIndex === 0 ? 'medium' : 'normal',
                    color: cellIndex === 0 ? '#333' : '#555'
                  }}
                >
                  {cell}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

// Componente para renderizar listas
const DataList = ({ items }) => {
  return (
    <Box sx={{ mb: 4 }}>
      {items.map((item, index) => (
        <Card 
          key={index} 
          elevation={2} 
          sx={{ 
            mb: 2, 
            borderRadius: '8px',
            transition: 'transform 0.2s, box-shadow 0.2s',
            '&:hover': {
              transform: 'translateY(-3px)',
              boxShadow: '0 8px 20px rgba(0, 0, 0, 0.1)'
            }
          }}
        >
          <CardContent sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              <WarningAmberIcon sx={{ color: '#800040', mr: 2, mt: 0.5 }} />
              <Typography variant="body1" color="text.secondary">
                {item}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

// Componente principal
const Results = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Definir secciones con títulos descriptivos
  const sections = [
    { 
      title: "Patrones de Delitos por Alcaldía", 
      icon: <LocationCityIcon />,
      data: [resultsData.document[0], resultsData.document[1]]
    },
    { 
      title: "Delitos Específicos por Zona", 
      icon: <WarningAmberIcon />,
      data: [resultsData.document[2], resultsData.document[3]]
    },
    { 
      title: "Análisis por Grupos de Edad", 
      icon: <PeopleAltIcon />,
      data: [resultsData.document[4], resultsData.document[5], resultsData.document[6], resultsData.document[7]]
    },
    { 
      title: "Delitos Graves y Correlaciones", 
      icon: <InsightsIcon />,
      data: [resultsData.document[8], resultsData.document[9], resultsData.document[10], resultsData.document[11]]
    },
    { 
      title: "Patrones Temporales de Delitos", 
      icon: <AccessTimeIcon />,
      data: [resultsData.document[12], resultsData.document[13]]
    }
  ];

  return (
    <div className={styles.container}>
      <Fade in={true} timeout={800}>
        <Box sx={{ mb: 4 }}>
          <Typography 
            variant="h4" 
            component="h1" 
            sx={{ 
              fontWeight: 'bold', 
              color: '#800040',
              mb: 1,
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <AssessmentIcon sx={{ mr: 1, fontSize: 32 }} />
            Resultados del Análisis de Datos
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 3 }}>
            Patrones y correlaciones identificados en los datos de delitos en la Ciudad de México
          </Typography>
          <Divider sx={{ mb: 3 }} />
        </Box>
      </Fade>

      {/* Mostrar todas las secciones de datos en el orden original */}
      {sections.map((section, sectionIndex) => (
        <Box key={sectionIndex} sx={{ mb: 5 }}>
          <Typography 
            variant="h5" 
            sx={{ 
              mb: 3, 
              color: '#800040', 
              display: 'flex', 
              alignItems: 'center',
              borderBottom: '2px solid #f0f0f0',
              pb: 1
            }}
          >
            {section.icon}
            <Box sx={{ ml: 1 }}>{section.title}</Box>
          </Typography>
          
          {section.data.map((item, itemIndex) => (
            <Box key={itemIndex} sx={{ mb: 4 }}>
              {item.type === "table" && (
                <DataTable 
                  headers={item.headers} 
                  subheaders={item.subheaders} 
                  rows={item.rows} 
                />
              )}
              {item.type === "list" && (
                <DataList items={item.items} />
              )}
            </Box>
          ))}
        </Box>
      ))}

      <Divider sx={{ my: 4 }} />

      {/* Sección de visualización de mapas con más espacio */}
      <Box sx={{ mb: 4 }}>
        <Typography 
          variant="h4" 
          sx={{ 
            mb: 3, 
            color: '#800040', 
            display: 'flex', 
            alignItems: 'center',
            borderBottom: '2px solid #f0f0f0',
            pb: 1
          }}
        >
          <InsightsIcon sx={{ mr: 1 }} />
          Visualización de Datos
        </Typography>
      </Box>

      {/* Mapas con más espacio y mejor visualización */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ 
                backgroundColor: '#800040',
                color: 'white',
                '&.Mui-expanded': {
                  minHeight: '48px',
                }
              }}
            >
              <Typography sx={{ fontWeight: 'medium' }}>Mapa de Delitos de Género</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0, height: '70vh' }}>
              <AsociationMap markerType="delitos_genero" type="Genero" />
            </AccordionDetails>
          </Accordion>
        </Grid>
        
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ 
                backgroundColor: '#800040',
                color: 'white',
                '&.Mui-expanded': {
                  minHeight: '48px',
                }
              }}
            >
              <Typography sx={{ fontWeight: 'medium' }}>Mapa de Delitos Violentos</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0, height: '70vh' }}>
              <Box sx={{ height: '100%', position: 'relative' }}>
                <AsociationMap markerType="delitos_violentos" type="Violentos" />
              </Box>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>

      {/* Tabla adicional de delitos violentos */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" sx={{ mb: 3, color: '#800040' }}>
          Reglas de Asociación - Delitos Violentos
        </Typography>
        <DataTable 
          headers={delitosViolentosData.headers} 
          subheaders={delitosViolentosData.subheaders} 
          rows={delitosViolentosData.rows} 
        />
      </Box>

      <Box sx={{ mt: 4, p: 3, bgcolor: '#f8f8f8', borderRadius: '8px', textAlign: 'center' }}>
        <Typography variant="body2" color="text.secondary">
          Los datos presentados en este análisis provienen de registros oficiales y carpetas de investigación.
          Para más información, consulte las fuentes oficiales de la Fiscalía General de Justicia de la Ciudad de México.
        </Typography>
      </Box>
    </div>
  );
};

export default Results;
